<template>
  <div>
    <change-password-auth
      v-model="changePassword"
      :user="user"
    />
    <b-card
      :title="$t('Profile')"
    >
      <b-row>
        <b-col
          v-if="userData.avatar"
          cols="12"
          md="4"
          class="d-flex justify-content-center mb-2"
        >
          <b-avatar
            :src="`${$fullPath}${userData.avatar}`"
            size="8rem"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <h3>{{ userData.first_name }} {{ userData.last_name }}</h3>
          <h5>{{ userData.user_name }}</h5>
        </b-col>
        <b-col
          cols="12"
          md="3"
        >
          <b-row>
            <b-col cols="12">
              <b-badge variant="light-primary">
                {{ userData.email }}
              </b-badge></b-col>
            <b-col cols="12">
              <b-badge
                class="mt-1"
                variant="light-primary"
              >
                {{ userData.phone }}
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="userData.role"
          cols="12"
          md="2"
          class="mt-md-0 mt-1"
        >
          <b-badge variant="light-primary">
            {{ userData.role.name }}
          </b-badge>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click="ChangePassword()"
          >
            {{ $t('Change Password') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      :title="$t('Edit Profile')"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="d-flex justify-content-center mt-5"
        >
          <upload
            v-model="userData.avatar_id"
          />
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="userData.first_name"
                  class="mt-2"
                  :state="errors.first_name ? false:null"
                  :placeholder="$t('First Name')"
                />
                <small
                  v-if="errors.first_name"
                  class="text-danger"
                >{{ errors.first_name[0] }}</small>
                <label>{{ $t('First Name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="userData.last_name"
                  :state="errors.last_name ? false:null"
                  class="mt-2"
                  :placeholder="$t('Last Name')"
                />
                <small
                  v-if="errors.last_name"
                  class="text-danger"
                >{{ errors.last_name[0] }}</small>
                <label>{{ $t('Last Name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="userData.user_name"
                  class="mt-2"
                  :state="errors.user_name ? false:null"
                  :placeholder="$t('User Name')"
                />
                <small
                  v-if="errors.user_name"
                  class="text-danger"
                >{{ errors.user_name[0] }}</small>
                <label>{{ $t('User Name') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="userData.email"
                  class="mt-2"
                  :state="errors.email ? false:null"
                  :placeholder="$t('Email')"
                />
                <small
                  v-if="errors.email"
                  class="text-danger"
                >{{ errors.email[0] }}</small>
                <label>{{ $t('Email') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="userData.phone"
                  class="mt-2"
                  :state="errors.phone ? false:null"
                  :placeholder="$t('Phone')"
                  type="number"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('Phone') }}</label>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-3"
        >
          <b-button
            variant="gradient-success"
            @click="Update"
          >
            {{ $t('Confirm') }}
          </b-button>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
import Upload from '@/layouts/components/Upload.vue'
import ChangePasswordAuth from '@/views/auth/components/change_password_auth.vue'

export default {
  components: {
    ChangePasswordAuth,
    Upload,
  },
  data() {
    return {
      user: null,
      userData: {
        avatar_id: null,
      },
      errors: [],
      changePassword: false,
    }
  },
  computed: {
    User() {
      return this.$store.getters['auth/GetAuth']
    },
  },
  watch: {
    User(val) {
      this.userData = { ...val }
    },
  },
  created() {
    this.userData = { ...this.$store.getters['auth/GetAuth'] }
  },
  methods: {
    ChangePassword() {
      this.user = this.userData.id
      this.changePassword = true
    },
    Update() {
      this.$store.dispatch('auth/UpdateProfile', this.userData).then(() => {
        this.errors = []
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
